<template>
    <div class="select-currency">
        <h2>Select Currency</h2>

        <hooper class="hooper" :itemsToShow="2.4" :centerMode="true" :initialSlide="1" @slide="onSlide">
            <slide class="slide" v-for="(curr,key) in currency" :key="key">
                <img :src="curr.img" height="100px" class="currency-img">
                <h3 class="currency-name">{{curr.name}}</h3>
            </slide>
        </hooper>


        <div class="send-container">
            <button class="btn btn-block bt-primary" v-on:click="$emit('next', currency[selectedCurrency]
            )">NEXT
            </button>
        </div>
    </div>
</template>

<script>
    import {Hooper, Slide} from 'hooper';
    import 'hooper/dist/hooper.css';

    export default {
        name: "SelectCurrency",
        components: {
            Hooper,
            Slide
        },
        data: function () {
            return {
                selectedCurrency: "ETH",
                currency: {
                    BTC: {
                        name: "BTC",
                        network: 'BTC',
                        contract: '',
                        img: "https://s2.coinmarketcap.com/static/img/coins/128x128/1.png"
                    },
                    ETH: {
                        name: "ETH",
                        network: 'ETH',
                        contract: '',
                        img: "https://s2.coinmarketcap.com/static/img/coins/128x128/1027.png"
                    },
                    ADA: {
                        name: "ADA",
                        network: 'ADA',
                        contract: '',
                        img: "https://s2.coinmarketcap.com/static/img/coins/128x128/2010.png"
                    },
                    BNB: {
                        name: "BNB",
                        network: 'BNB',
                        contract: '',
                        img: "https://s2.coinmarketcap.com/static/img/coins/128x128/1839.png"
                    },
                    USDT: {
                        name: "USDT",
                        network: 'BTC',
                        contract: '',
                        img: "https://s2.coinmarketcap.com/static/img/coins/128x128/825.png"
                    },
                    DOGE: {
                        name: "DOGE",
                        network: 'BTC',
                        contract: '',
                        img: "https://s2.coinmarketcap.com/static/img/coins/128x128/74.png"
                    },
                    CAKE: {
                        name: "CAKE",
                        network: 'BNB',
                        contract: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
                        img: "https://s2.coinmarketcap.com/static/img/coins/128x128/7186.png"
                    }
                },
            }
        },
        methods: {
            onSlide: function (data) {
                this.selectedCurrency = Object.keys(this.currency)[data.currentSlide];
                console.log('slide', Object.keys(this.currency)[data.currentSlide])
            }
        }
    }
</script>

<style scoped>
    .slide {
        /*border: 1px solid black;*/
        /*border-radius: 7px;*/
    }

    .select-currency {
        margin-top: 90px;
    }

    .slide {
        /*background: linear-gradient(to right, cornflowerblue, transparent 90%);*/
    }

    .currency-name, h2 {
        color: white;
    }
</style>