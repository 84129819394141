<template>
    <div class="home">
        <img alt="Vue logo" src="../assets/logo.png">
        <h1>HOME</h1>
    </div>
</template>

<script>

    export default {
        name: 'Home',
        components: {}
    }
</script>
