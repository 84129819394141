<template>
    <div class="amount-summary" v-on:click="$emit('click')">
        <span>Amount</span>
        <p>{{transaction.amount}}</p>
    </div>
</template>

<script>
    export default {
        name: "AmountSummary",
        props: {
            transaction: Object
        }
    }
</script>

<style scoped>
    .amount-summary {
        color: white;
        font-size: 1.4rem;
        position: relative;
        border-radius: 7px;
        border: 1px solid white;
        padding: 15px;
        margin-top: 15px;
    }

    .amount-summary img {
        height: 32px;
    }

    .amount-summary p {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 2rem;
        font-weight: bolder;
    }

    .amount-summary span {
        font-size: 0.8rem;
        position: absolute;
        top: 10px;
        left: 10px;
        font-weight: bold;
        z-index: 2;
    }
</style>