<template>
    <div class="select-wallet">
        <h2>Select Wallet</h2>

        <Wallet v-for="wallet in registeredWallets" :key="wallet.name" :name="wallet.name" :img="wallet.img"
                :background="wallet.background" :send-src="wallet.src()"></Wallet>

    </div>
</template>

<script>
    import BigNumber from 'bignumber.js';
    import Wallet from "./Wallet";

    export default {
        name: "SelectWallet",
        props: {
            wallets: Object,
            transaction: Object
        },
        components: {Wallet},
        data: function () {
            return {
                registeredWallets: [
                    {
                        name: 'TrustWallet',
                        background: '#3375BB',
                        img: 'https://trustwallet.com/assets/images/media/assets/trust_platform.svg',
                        src: this.trustWalletSrc
                    },
                    {
                        name: 'MetaMask',
                        background: '#F89C35',
                        img: 'https://cdn.iconscout.com/icon/free/png-512/metamask-2728406-2261817.png',
                        src: this.metaMaskSrc
                    },
                    // {
                    //     name: 'Binance',
                    //     background: '#161A1E',
                    //     img: 'https://cdn.freebiesupply.com/logos/large/2x/binance-coin-logo-png-transparent.png',
                    //     src: this.trustWalletSrc
                    // },
                    // {
                    //     name: 'CoinBase',
                    //     background: '#065AB6',
                    //     img: 'https://cryptotesters-images.s3.eu-central-1.amazonaws.com/a98a3a73352540c99c29390710399e63ct_a5619750ff.png',
                    //     src: this.coinbaseSrc
                    // },
                ]
            }
        },
        methods: {
            trustWalletSrc: function () {
                return `trust://send?address=${this.wallets[this.transaction.token.network]}&asset=${this.toTrustWalletAsset(this.transaction.token.name)}&amount=${this.transaction.amount}}`
                // return `https://link.trustwallet.com/send?address=${this.wallets[this.transaction.token.network]}&asset=${this.toTrustWalletAsset(this.transaction.token.name)}&amount=${this.transaction.amount}`;
            },
            metaMaskSrc: function () {
                if (this.transaction.token.network === 'ETH') {
                    return `https://metamask.app.link/send/${this.wallets[this.transaction.token.network]}?value=${this.transaction.amount}e${19 + this.transaction.amount.length}`;
                }

                const bn = new BigNumber(this.transaction.amount, 10);
                const amount = bn.toExponential().replace('+', '').replace('e0', '');

                return `https://metamask.app.link/send/${this.transaction.token.contract}/transfer?address=${this.wallets[this.transaction.token.network]}&uint256=${amount}`;
            },
            binanceSrc: function () {
                return '';
            },
            coinbaseSrc: function () {
                return '';
            },
            toTrustWalletAsset(tokenName) {
                console.log("TW", {
                    t: this.transaction,
                    w: this.wallets
                });

                switch (tokenName) {
                    default:
                    case "BTC":
                        return 'c0'
                }
            }
        }
    }
</script>

<style scoped>

</style>