<template>
    <div class="currency-summary" v-on:click="$emit('click')">
        <span class="title">Currency</span>
        <p><img :alt="transaction.token.name" :src="transaction.token.img"> <span class="token-name">{{transaction.token.name}}</span>
        </p>
    </div>
</template>

<script>
    export default {
        name: "CurrencySummary",
        props: {
            transaction: Object
        }
    }
</script>

<style scoped>
    .currency-summary {
        color: white;
        font-size: 1.4rem;
        border-radius: 7px;
        border: 1px solid white;
        padding: 15px;
        position: relative;
        margin-top: 15px;
    }

    .currency-summary img {
        height: 32px;
    }

    .currency-summary p {
        margin-bottom: 0;
        margin-top: 0;
        line-height: 32px;
    }

    .token-name {
        vertical-align: top;
    }

    .currency-summary span.title {
        font-size: 0.8rem;
        position: absolute;
        top: 10px;
        left: 10px;
        font-weight: bold;
        z-index: 2;
    }
</style>