<template>
    <div class="scan-home">
        <TagInfo :tag="tag" class="tag-info"></TagInfo>

        <SelectCurrency v-show="idx === 0" @next="onCurrencyNext"
                        class="csc animate__animated  animate__fadeInDownBig"></SelectCurrency>
        <CurrencySummary @click="setIdx(0)" :transaction="transaction" v-show="idx > 0"
                         class="csc csc-summary animate__animated  animate__fadeIn"></CurrencySummary>

        <SelectAmount :token="transaction.token" v-show="idx === 1" @next="onAmountNext"
                      class="cwc animate__animated animate__fadeInDownBig"></SelectAmount>
        <AmountSummary @click="setIdx(1)" v-show="idx > 1" :transaction="transaction"
                       class="csc csc-summary animate__animated  animate__fadeIn"></AmountSummary>

        <SelectWallet :wallets="addressMap" :transaction="transaction" v-show="idx === 2" @next="onWalletNext"
                      class="cwc animate__animated animate__fadeInDownBig"></SelectWallet>

    </div>
</template>

<script>
    import TagInfo from "../components/TagInfo";
    import SelectCurrency from "../components/SelectCurrency";
    import SelectWallet from "../components/SelectWallet";
    import SelectAmount from "../components/SelectAmount";
    import CurrencySummary from "../components/CurrencySummary";
    import AmountSummary from "../components/AmountSummary";

    export default {
        name: "ScanHome",
        components: {AmountSummary, CurrencySummary, SelectWallet, SelectCurrency, TagInfo, SelectAmount},
        data: function () {
            return {
                idx: 0,
                tag: {
                    name: `Scott's`
                },
                addressMap: {
                    BTC: '',
                    ETH: '',
                    BNB: '',
                    MATIC: '',
                    POLY: '',
                },
                transaction: {
                    token: {
                        name: '',
                        network: 'BTC',
                        img: ''
                    },
                    amount: 0,
                    note: '',
                }
            }
        },
        methods: {
            onCurrencyNext: function (currency) {
                console.log("Using currency: ", currency);
                this.transaction.token = currency;
                this.next();
            },
            onAmountNext: function (amount) {
                console.log("Using amount: ", amount);
                this.transaction.amount = amount;
                this.next();
            },
            onWalletNext: function () {
                //TODO
                this.next();
            },

            next: function () {
                this.idx = this.idx + 1;
                console.log('idx', this.idx)
            },

            prev: function () {
                if (this.idx <= 0)
                    this.idx = 0;
                else
                    this.idx = this.idx - 1;
            },

            setIdx: function (idx) {
                this.idx = idx;
            },

            parseScanCode: function (code) {
                const decoded = window.atob(code);
                console.log('decoded', decoded);

                const splits = decoded.split(';');
                console.log('splits', splits);
                for (let idx in splits) {
                    const s = splits[idx].split(":");
                    this.addressMap[s[0]] = s[1]
                }

                console.log("Loaded Wallet map", this.addressMap);
            }
        },
        beforeMount() {
            //TODO Support multi-address via base64 encoded ID

            console.log('address', this.$route.params.id);
            this.parseScanCode(this.$route.params.id);
        }
    }
</script>

<style>
    body {
        background: cornflowerblue;
        color: white;
    }

    h1, h2, h3, h4 {
        color: white;
    }


    .csc, .cwc {
        margin-top: 30px;
        margin-right: 15px;
        margin-left: 15px;
    }

    .btn {
        width: 60%;
        border-radius: 7px;
        border: 3px solid white;
        background: transparent;
        color: white;
        text-align: center;
        font-size: 3rem;
    }

    .csc-summary {
        cursor: pointer;
    }

</style>