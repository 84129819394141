<template>
    <div class="select-amount">
        <h3>Select Amount</h3>

        <h2><input v-on:focusin="clearInput" v-model="amount" class="amount-input" step='0.01'  min="0.00" type="number"> <span>{{token.name}}</span></h2>


        <div class="send-container">
            <button class="btn btn-block bt-primary" v-on:click="$emit('next', amount
            )">NEXT</button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SelectWallet",
        props: {
            token: Object,
        },
        data: function() {
            return {
                amount: '1.00'
            }
        },
        methods: {
            clearInput: function() {
                this.amount = ''
            }
        }
    }
</script>

<style scoped>
    input {
        background: transparent;
        border: none;
        color: white;
        font-size: 3rem;
        text-align: center;
        width:100%;
        margin-bottom: 5px;
    }

    .select-amount {
        margin-top: 90px;
    }
</style>