<template>
    <div class="generate">
        <h1>Generate Link</h1>
        <div class="input">
            <h4>Enter in your network addresses below to generate a crypto-link</h4>
            <form @submit="generate">
                <label for="BTC">BTC: </label>
                <input id="BTC" name="BTC" class="address-input" v-model="addressMap.BTC" placeholder="BTC Address">
                <label for="ETH">ETH: </label>
                <input id="ETH" name="ETH" class="address-input" v-model="addressMap.ETH" placeholder="ETH Address">
                <label for="BNB">BNB: </label>
                <input id="BNB" name="BNB" class="address-input" v-model="addressMap.BNB" placeholder="BNB Address">
                <label for="MATIC">MATIC: </label>
                <input id="MATIC" name="MATIC" class="address-input" v-model="addressMap.MATIC"
                       placeholder="MATIC Address">
                <label for="POLY">POLY: </label>
                <input id="POLY" name="POLY" class="address-input" v-model="addressMap.POLY"
                       placeholder="POLYGON Address">

            </form>
            <button @click="generate" class="form-submit">GENERATE</button>
        </div>

        <div class="result" v-if="code !== ''">
            <h3>Code</h3>
            <span class="code">{{code}}</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Generate",
        data: function () {
            return {
                code: '',
                addressMap: {
                    BTC: '',
                    ETH: '',
                    BNB: '',
                    MATIC: '',
                    POLY: '',
                }
            }
        },
        methods: {
            generate: function (e) {
                e.preventDefault();
                console.log("Generating code");

                let code = '';
                for (const k in this.addressMap) {
                    const addr = this.addressMap[k];
                    if (addr === '')
                        continue;

                    code = code + `${k}:${addr};`;
                }

                console.log("Code", code);

                this.code = btoa(code);
                console.log("Encoded", this.code)
            }
        }
    }
</script>

<style scoped>
    .generate {
        width: 60%;
        padding-left: 20%;
    }

    .address-input {
        width: 100%;
        padding: 15px;
        margin: 5px;
    }

    form {
        text-align: left;
    }

    h4 {
        margin-bottom: 60px;
    }

    label {
        text-align: left;
        color: white;
        font-size: 1.2rem;
        font-weight: bold;
    }

    .result {
        margin-top: 30px;
        border: 2px solid white;
        color: white;
        padding: 0px 30px 30px;
        font-size: 1.6rem;
    }

    .code {

    }

    .form-submit {
        width: 30%;
        padding: 15px;
        margin-top: 15px;
        background: transparent;
        color: white;
        font-size: 2rem;
        border: 3px solid white;
        border-radius: 7px;
    }
</style>