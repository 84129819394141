import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import ScanHome from '../views/ScanHome'
import Generate from '../views/Generate'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/generate',
        name: 'Generate Link',
        component: Generate
    },
    {
        path: '/nfc/:id',
        name: 'NFC Scan',
        component: ScanHome
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
