<template>
    <a :href="sendSrc" class="wallet" :style="`background: ${background}`">
        <div class="block w-20"><img height="50px" :src="img"></div>
        <div class="block w-80"><p>{{name}}</p></div>
    </a>
</template>

<script>
    export default {
        name: "Wallet",
        props: {
            name: String,
            sendSrc: String,
            background: String,
            img: String
        }
    }
</script>

<style scoped>
    .wallet {
        cursor: pointer;
        border: 1px solid white;
        padding: 10px;
        margin-top: 15px;
        border-radius: 7px;
        color: white;
        font-weight: bold;
        display: flex;
    }

    .w-80 {
        width: 80%;
    }

    .w-20{
        width: 20%;
    }

    .block:first-child {
        width: 10%;
    }

</style>