<template>
    <div class="tag-info">
        <h3>SCANNED</h3>
        <h1>{{tag.name}} Tag</h1>
    </div>
</template>

<script>
    export default {
        name: "TagInfo",
        props: {
            tag: Object
        }
    }
</script>

<style scoped>
    h1 {
        color: white;
        margin-top: 0;
        text-transform: uppercase;
    }

    h3 {
        color: white;
        margin-bottom: 0;
        text-transform: uppercase;
    }
</style>